.PopupBuildingInfo {
    width: 700px;
    height: 100%;

    .content {
        width: 100%;
        height: 100%;
    }

    .blockContent {
        padding: 15px;
        display: flex;
        flex-wrap: wrap;
        overflow-x: hidden;
    }

    .avatarContent {
        position: relative;
        margin-bottom: 20px;
    }

    .tags {
        width: calc(100% - 20px);
        position: absolute;
        left: 10px;
        top: 8px;
        z-index: 3;

        > div {
            margin-right: 5px;
            background: #ffcc01;
            font-size: 14px;
            padding: 2px 5px;
            display: inline-block;
        }
    }

    .passed {
        position: absolute;
        left: 10px;
        bottom: 8px;
        width: calc(100% - 20px);
        font-size: 14px;
        line-height: 14px;
        padding: 5px 0;
        text-align: center;
        background: #075ea5;
        color: #fff;
        z-index: 3;

        &.is {
            background: #ffcc01;
            color: #000;
        }
    }

    .information {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .icon {
            margin-right: 20px;
            color: #999;

            svg {
                height: 15px;
                vertical-align: middle;
            }

            span {
                font-size: 12px;
                line-height: 15px;
                vertical-align: middle;
                margin-left: 5px;
            }
        }
    }

    .itemContent {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        h2 {
            font-size: 22px;
            margin-bottom: 30px;
            line-height: 24px;
            position: relative;
        }

        .address {
            flex-grow: 1;

            span {
                display: block;
            }
        }

        .payments,
        .formalization {
            width: 100%;
            margin-top: 10px;

            > div {
                background-color: #ffcc01;
                margin-right: 10px;
                padding: 3px 6px;
                display: inline-block;
                font-size: 14px;
            }
        }
    }

    .itemInfo {
        width: calc(100% - 350px);
        flex-shrink: 0;
        padding: 0 0 0 20px;

        .type {
            background: #075ea5;
            padding: 3px 6px;
            display: inline-block;
            font-size: 14px;
            margin-bottom: 10px;
            color: #fff;
        }

        .counter {
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 25px;
        }

        .cost {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 5px;

            .costDown {
                display: inline-block;
                margin-left: 5px;
                color: #7bad17;
                font-size: 16px;
            }

            .costUp {
                display: inline-block;
                margin-left: 5px;
                color: #a90707;
                font-size: 16px;
            }
        }

        .costPer {
            margin-bottom: 25px;
        }

        .area {

        }
    }
}

@media (max-width: 768px) {
    .PopupBuildingInfo {
        width: 500px;

        .avatarContent {
            > div:first-child {
                width: 460px !important;
                height: 460px !important;
            }
        }

        .itemInfo {
            width: 100%;
            padding: 0;

            .costPer {
                margin-bottom: 0;
            }

            .area {
                margin-bottom: 20px;
            }
        }
    }
}

@media (max-width: 480px) {
    .PopupBuildingInfo {
        width: 350px;

        .avatarContent {
            > div:first-child {
                width: 320px !important;
                height: 320px !important;
            }
        }
    }
}