.BuildingList {
    height: calc(100% - 118px);

    .name {
        flex-grow: 1;
    }

    .author {
        width: 200px;
        flex-shrink: 0;
    }

    .type {
        width: 150px;
        flex-shrink: 0;
    }

    .views {
        width: 100px;
        flex-shrink: 0;
    }
}

@media (max-width: 768px) {
    .BuildingList {
        display: block;
        padding: 0;
        margin-bottom: 0;

        .name,
        .author,
        .type,
        .views {
            position: relative;
            display: block;
            width: 100%;
            padding-right: 8px;
            text-overflow: ellipsis;
            white-space: nowrap;

            &::before {
                display: inline-block;
                width: 100px;
                padding: 8px 10px;
                background-color: #075ea5;
                color: #fff;
                margin-right: 10px;
                border-bottom: 1px solid #fff;
            }
        }

        .name::before {
            content: 'Название';
        }

        .author::before {
            content: 'Автор';
        }

        .type::before {
            content: 'Тип';
        }

        .views::before {
            content: 'Просмотры';
            border-bottom: none;
        }
    }
}

@media (max-width: 480px) {
    .BuildingList {
        font-size: 13px;

        .name,
        .author,
        .type,
        .views {
            &::before {
                width: 80px;
            }
        }
    }
}