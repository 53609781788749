.PopupSupportInfo {
    width: 700px;
    height: 100%;

    .content {
        width: 100%;
        height: 100%;

        .blockContent {
            padding: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            overflow-x: hidden;
        }

        .tabContent {
            padding: 20px 0;
        }

        .blockMessages {
            width: 100%;
            margin-top: 30px;

            .field {
                width: 100%;
                margin-bottom: 10px;
                position: relative;
            }
        }

        .messageList {
            width: 100%;

            .item {
                margin-bottom: 10px;
                border-bottom: 1px solid #075ea5;
                padding-bottom: 10px;

                .head {
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: space-between;

                    .name {
                        font-weight: 700;
                        color: #075ea5;
                    }

                    .date {
                        color: #999;
                        font-size: 12px;
                    }
                }

                .description {
                    font-size: 14px;
                    line-height: 16px;
                    white-space: pre-wrap;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .PopupSupportInfo {
        width: 500px;
    }
}

@media (max-width: 480px) {
    .PopupSupportInfo {
        width: 350px;
    }
}