.PopupDistrictSelector {
    width: 700px;
    height: 100%;

    .content {
        width: 100%;
        height: 100%;
    }

    .blockContent {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow-x: hidden;
    }

    .district {
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;

        > div {
            width: 100%;
        }

        + .district {
            margin-top: 20px;
        }

        .field {
            width: 33.33%;
            position: relative;
            display: flex;
            margin-bottom: 10px;

            > div:first-child {
                width: 110px;
                flex-shrink: 0;
            }

            > div:last-child {
                flex-grow: 1;
            }

            &.fieldWrap {
                flex-wrap: wrap;
            }
        }
    }
}

@media (max-width: 768px) {
    .PopupDistrictSelector {
        width: 500px;

        .district {
            .field {
                width: 50%;
            }
        }
    }
}

@media (max-width: 480px) {
    .PopupDistrictSelector {
        width: 350px;

        .district {
            .field {
                width: 100%;

                + .field {
                    margin-top: 5px;
                }
            }
        }
    }
}