.Label {
    font-size: 14px;
    color: #000;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .generatePassword {
        display: block;
        margin-left: 10px;
        font-size: 12px;
        color: #aaa;
        cursor: pointer;

        &:hover {
            color: #777;
        }
    }
}