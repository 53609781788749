.Title {
    display: flex;
    flex-wrap: wrap;

    &.left {
        justify-content: left;

        > span {
            margin-right: auto;
        }
    }

    &.center {
        justify-content: center;

        > span {
            margin-left: auto;
        }
    }

    &.right {
        justify-content: right;

        > span {
            margin-left: auto;
            margin-right: 0;
        }
    }

    > span {
        display: block;
        margin-right: auto;
    }

    &.hasSearch {
        flex-wrap: wrap;

        > span {
            flex-grow: 1;
            width: 100%;
        }

        .interface {
            flex-grow: 1;
            width: 100%;
            margin-top: 15px;
        }
    }

    .interface {
        display: flex;
        justify-content: right;

        .search {
            width: 100%;
            flex-grow: 1;
        }
    }
}

h1 {
    font-size: 28px;
    line-height: 32px;
    margin-top: 5px;
    margin-bottom: 15px;
}

h2 {
    text-transform: uppercase;
    color: #888;
    font-size: 18px;
    display: block;
    padding-bottom: 10px;
    margin-bottom: 15px;
    position: relative;

    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 150px;
        height: 1px;
        background: #ffcc01;
    }

    &.center {
        &::after {
            left: calc(50% - 75px);
        }
    }

    &.right {
        &::after {
            left: auto;
            right: 0;
        }
    }
}

h3 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 15px;
}

h4 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 15px;
}

h5 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
}

h6 {
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 10px;
}