.Navigation {
    width: 46px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #033966;
    position: fixed;
    left: 0;
    top: 0;
    box-shadow: 3px 0 8px rgba(0, 0, 0, 0.4);
    z-index: 7;

    .profile {
        cursor: pointer;
    }

    .userPanel {
        background-color: #033966;
        position: absolute;
        top: 0;
        left: 46px;
        width: 0;
        height: 46px;
        overflow: hidden;
        transition: all 0.3s ease-in;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        z-index: 7;
        display: flex;

        &.show {
            width: 350px;
        }

        .userName {
            color: #fff;
            padding: 3px 8px;
            width: 150px;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .name {
                display: block;
                font-size: 16px;
                line-height: 16px;
                margin-bottom: 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .post {
                display: block;
                font-size: 13px;
                line-height: 13px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .icon {
            flex-shrink: 0;
            width: 50px;
            height: 46px;
            color: #fff;
            transition: all 0.3s ease-in;
            cursor: pointer;
            text-align: center;
            position: relative;
            border-left: 1px solid #fff;

            &:hover {
                opacity: 0.7;
            }

            a {
                color: #fff;
            }

            svg {
                font-size: 18px;
                margin: 8px 0 5px;
            }

            span {
                font-size: 9px;
                line-height: 9px;
                display: block;
                text-align: center;
                color: #999;
            }
        }
    }

    .menu {
        list-style: none;
        padding: 5px 0;
        margin: 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        li {
            line-height: 46px;
            display: block;
            position: relative;
            padding: 5px;

            &.spacer {
                height: 1px;

                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: #fff;
                }
            }

            a {
                color: #fff;
                text-decoration: none;
                display: block;

                &.active,
                &:hover {
                    color: #ffcc01;

                    .icon {
                        border: 2px solid #ffcc01;
                    }
                }

                .icon {
                    font-size: 16px;
                    line-height: 40px;
                    display: flex;
                    width: 36px;
                    height: 36px;
                    align-items: center;
                    justify-content: center;
                    border: 2px solid #fff;
                    border-radius: 50%;
                    transition: all 0.3s ease-in;
                }

                .title {
                    font-size: 10px;
                    line-height: 10px;
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .Navigation {
        display: none;
        padding-top: 46px;

        &.show {
            display: flex;
        }

        .userPanel {
            &.show {
                width: 325px;
            }

            .userName {
                width: 125px;
            }
        }
    }
}