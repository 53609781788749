.PopupFileManager {
    width: 700px;
    height: 100%;

    .content {
        width: 100%;
        height: 100%;
    }

    .blockContent {
        padding: 15px;
        overflow-x: hidden;
        height: 100%;
    }

    .uploader {
        padding: 5px;
        display: flex;
        justify-content: space-between;

        > div:first-child {
            margin-right: 20px;
        }
    }

    .list {
        margin: 0;
    }
}

@media (max-width: 768px) {
    .PopupFileManager {
        width: 500px;
    }
}

@media (max-width: 480px) {
    .PopupFileManager {
        width: 350px;
    }
}