.MessengerItem {
    display: flex;
    cursor: pointer;
    padding: 5px;

    + .MessengerItem {
        margin-top: 10px;
    }

    &:hover {
        background-color: #ffcc01;
    }

    .info {
        margin-left: 10px;
        flex-grow: 1;

        .name {
            font-size: 14px;
            font-weight: 700;
            color: #075ea5;

            span {
                display: inline-block;
                vertical-align: middle;
            }

            .indicator {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #a90707;
                margin-left: 10px;

                &.online {
                    background-color: #7bad17;
                }
            }
        }

        .text {
            margin-top: 10px;
            font-size: 12px;
        }
    }

    .meta {
        display: flex;
        flex-direction: column;

        span {
            width: 100px;
            font-size: 11px;
            color: #999;
            flex-shrink: 0;
            text-align: right;
        }

        .indicator {
            flex-grow: 1;
            position: relative;

            &::before {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                bottom: 0;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #075ea5;
                margin-left: 10px;
            }
        }
    }
}