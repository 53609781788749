.PopupAddressSelector {
    width: 700px;
    height: 100%;

    .content {
        width: 100%;
        height: calc(100% - 66px);
    }

    .blockContent {
        height: 100%;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow-x: hidden;

        .map {
            width: 100%;
            height: calc(100% - 96px);
        }
    }

    .field {
        width: 100%;
        position: relative;
        display: flex;

        > div:first-child {
            width: 110px;
            flex-shrink: 0;
        }

        > div:last-child {
            flex-grow: 1;
        }

        &.fieldWrap {
            flex-wrap: wrap;
        }
    }
}

@media (max-width: 768px) {
    .PopupAddressSelector {
        width: 500px;
    }
}

@media (max-width: 480px) {
    .PopupAddressSelector {
        width: 350px;
    }
}