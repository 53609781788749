.FormBuildRequest {
    padding-right: 20px;
    height: 100%;
    display: flex;
    align-items: center;

    .inner {
        width: 100%;
        height: 100%;
        overflow: hidden;

        .title {
            color: #fff;
            font-size: 30px;
            line-height: 34px;
        }

        .buttons {
            margin-top: 40px;
        }
    }
}

@media (max-width: 768px) {
    .FormBuildRequest {
        padding-right: 0;
        margin-bottom: 40px;
    }
}