.PopupPassedSelector {
    width: 700px;
    height: 100%;

    .content {
        width: 100%;
        height: 100%;
    }

    .blockContent {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow-x: hidden;
    }

    .field {
        width: 100%;
        position: relative;
        display: flex;

        + .field {
            margin-top: 30px;
        }

        > div:first-child {
            width: 110px;
            flex-shrink: 0;
        }

        > div:last-child {
            flex-grow: 1;
        }

        &.fieldWrap {
            flex-wrap: wrap;
        }
    }

    .radio {
        display: flex;
        align-items: center;

        span {
            display: block;
            width: 40px;
            height: 40px;
            border-top: 1px solid #075ea5;
            border-left: 1px solid #075ea5;
            border-bottom: 1px solid #075ea5;
            color: #075ea5;
            line-height: 38px;
            text-align: center;
            transition: all 0.3s ease-in;
            cursor: pointer;

            &:hover,
            &.active {
                background: #075ea5;
                color: #fff;
            }

            &:last-child {
                border-right: 1px solid #075ea5;
            }
        }
    }
}

@media (max-width: 768px) {
    .PopupPassedSelector {
        width: 500px;
    }
}

@media (max-width: 480px) {
    .PopupPassedSelector {
        width: 350px;

        .field {
            + .field {
                margin-top: 15px;
            }
        }
    }
}