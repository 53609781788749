.PopupAuth {
    width: 700px;
    height: 100%;

    .content {
        width: 100%;
        height: 100%;

        .form {
            padding: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            overflow-x: hidden;

            .buttons {
                margin-top: 20px;
            }

            .links {
                margin-top: 20px;

                span {
                    display: inline-block;
                    margin: 0 10px;
                }

                strong {
                    cursor: pointer;

                    &:hover {
                        color: #ffcc01;
                    }
                }
            }

            .errorMessage {
                margin-top: 20px;
                padding: 5px 10px;
                border: 1px solid #fe5a9d;
                color: #fe5a9d;
                width: 100%;
                text-align: center;
            }
        }
    }
}

@media (max-width: 768px) {
    .PopupAuth {
        width: 500px;
    }
}

@media (max-width: 480px) {
    .PopupAuth {
        width: 350px;
    }
}