.SearchItem {
    display: flex;
    overflow: auto;
    width: 100%;
    padding: 5px 15px;
    overflow-wrap: anywhere;
    cursor: pointer;
    transition: all 0.3s ease-in;

    &:hover {
        background: #ffcc01;
    }

    .name {
        flex-grow: 1;
    }

    .date {
        width: 150px;
        flex-shrink: 0;
    }
}