.FileList {
    margin: 15px 0 0 0;
    width: 100%;
    height: calc(100% - 103px);

    &.overflowX {
        overflow-x: auto;
    }

    .content {
        display: flex;
        flex-wrap: wrap;
        width: auto;
        background: #fff;
        overflow-x: auto;
        max-height: 100%;

        .dnd {
            display: flex;
            width: 100%;
            overflow-x: auto;
        }

        .item {
            width: 73px;
            height: 73px;
            flex-shrink: 0;
            margin: 5px;
            border: 2px solid #075ea5;
            position: relative;
            overflow: hidden;
            cursor: pointer;

            &.selected {
                border: 2px solid #ffcc01;
            }

            .wrapper {
                width: 100%;
                height: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .icon {
                width: 100%;
                height: 100%;
                font-size: 30px;
                line-height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #075ea5;
            }

            .text {
                text-align: center;
                position: absolute;
                left: 0;
                bottom: 0;
                padding: 2px;
                z-index: 3;
                background: rgba(7, 94, 165, 0.5);
                color: #fff;
                font-size: 9px;
                overflow-wrap: anywhere;
                width: 100%;
            }
        }
    }
}