.PopupFeedCreate {
    width: 700px;
    height: 100%;

    .content {
        width: 100%;
        height: 100%;
    }

    .blockContent {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow-x: hidden;
    }

    .field {
        width: 100%;
        position: relative;
        display: flex;

        + .field {
            margin-top: 30px;
        }

        > div:first-child {
            width: 110px;
            flex-shrink: 0;
        }

        > div:last-child {
            flex-grow: 1;
        }

        &.fieldWrap {
            flex-wrap: wrap;
        }
    }

    .errorMessage {
        color: #fe5a9d;
        text-align: center;
        margin-bottom: 30px;
        padding: 5px 10px;
        border: 1px solid #fe5a9d;
        white-space: pre-wrap;
    }

    .successMessage {
        color: #075ea5;
        text-align: center;
        margin-bottom: 30px;
        padding: 5px 10px;
        border: 1px solid #075ea5;
        white-space: pre-wrap;
    }

    .btn {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        height: 36px;
        padding: 0 8px;
        min-width: 100px;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
        font-size: 14px;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
        border: 1px solid #075ea5;
        display: flex;
        justify-content: center;
        background: #075ea5;
        color: #fff;
        margin-top: 10px;

        &:hover {
            background: #fff;
            color: #075ea5;
        }
    }
}

@media (max-width: 768px) {
    .PopupFeedCreate {
        width: 500px;
    }
}

@media (max-width: 480px) {
    .PopupFeedCreate {
        width: 350px;

        .field {
            + .field {
                margin-top: 15px;
            }
        }
    }
}