.PopupQuestionInfo {
    width: 700px;
    height: 100%;

    .content {
        width: 100%;
        height: 100%;
    }

    .blockContent {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow-x: hidden;
    }

    .description {
        width: 100%;
    }

    .meta {
        margin-top: 20px;

        .row {
            font-size: 12px;
            line-height: 15px;
            color: #999;
            display: flex;
            align-items: center;

            + .row {
                margin-top: 5px;
            }

            svg {
                width: 20px;
                margin-right: 10px;
            }
        }
    }
}

@media (max-width: 768px) {
    .PopupQuestionInfo {
        width: 500px;
    }
}

@media (max-width: 480px) {
    .PopupQuestionInfo {
        width: 350px;
    }
}