.ImageCarousel {
    width: 100%;
    height: 100%;

    .image {
        width: 100%;
        height: 100%;
        background: #075ea5;

        > div {
            width: 100%;
            height: 600px;
            cursor: pointer;
        }

        .link {
            font-size: 0;
            line-height: 0;
            display: block;
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .video {
        width: 100%;
        height: 600px;
        background: #075ea5;
    }
}