.PopupBookingCreate {
    width: 700px;
    height: 100%;

    .content {
        width: 100%;
        height: 100%;
    }

    .blockContent {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow-x: hidden;
    }

    .field {
        width: 100%;
        position: relative;
        display: flex;

        + .field {
            margin-top: 30px;
        }

        > div:first-child {
            width: 150px;
            flex-shrink: 0;
        }

        > div:last-child {
            flex-grow: 1;
        }
    }

    .busyList {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .item {
            width: 90px;
            margin: 0 5px 5px 0;
            color: #000;
            padding: 5px 10px;
            font-size: 14px;
            text-align: center;

            &.free {
                background-color: #075ea5;
                color: #fff;
            }

            &.busy {
                background-color: #ddd;
            }
        }
    }
}

@media (max-width: 768px) {
    .PopupBookingCreate {
        width: 500px;
    }
}

@media (max-width: 480px) {
    .PopupBookingCreate {
        width: 350px;

        .field {
            + .field {
                margin-top: 15px;
            }
        }
    }
}