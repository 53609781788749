.DocumentList {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    box-sizing: border-box;
    overflow: hidden;

    .header {
        display: flex;
        height: 30px;
        flex: none;
        border-bottom: 1px solid #e8e8e8;
        align-items: center;
        width: 100%;
        background: #075ea5;
        color: #fff;

        > div {
            padding: 0 10px;
            border-right: 1px solid #fff;
        }
    }

    .addDocument {
        flex: none;
        display: flex;
        align-items: center;
        width: 100%;
        height: 33px;
        padding-left: 15px;
        background-color: #f7f7f7;
        color: #b8b8b8;
        border: none;
        border-bottom: 1px solid #e6e6e6;
        box-shadow: inset 0 0 0 1px #fff;
        box-sizing: border-box;
        cursor: pointer;
        transition: all 0.3s ease-in;

        &:hover {
            background-color: #e8f1f8;
            color: #075ea5;
        }

        svg {
            margin-right: 10px;
        }
    }

    .list {
        height: 100%;

        .row {
            display: flex;
            height: 30px;
            flex: none;
            border-bottom: 1px solid #e8e8e8;
            align-items: center;
            width: 100%;

            > div {
                padding: 0 10px;
                border-right: 1px solid #fff;
            }
        }
    }

    .name {
        flex-grow: 1;
        width: 100%;
    }

    .type {
        flex-shrink: 0;
        width: 150px;
        border-right: none !important;
    }
}