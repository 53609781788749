.popup {
    background-color: #fff;
    box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    max-height: 100vh;
}

.header {
    display: flex;
    align-items: center;
    height: 37px;
    flex: none;
    padding: 0 15px;
    border-bottom: 1px solid #075ea5;

    background: #075ea5;
    background: linear-gradient(135deg, #075ea5 0%, #ffcc01 100%);
}

.header_big {
    composes: header;
    height: 48px;
}

.title {
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #fff;
    font-weight: bold;
}

.header_big .title {
    font-size: 18px;
    color: #08a7b9;
    margin: 0 15px;
}

.items {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    min-width: 0;
}

.cross {
    flex: none;
    cursor: pointer;
    margin-left: 10px;
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in;
}

.cross:hover {
    opacity: 0.5;
}

.content {
    padding: 0;
}

.footer {
    flex: none;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.left {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.right {
    display: flex;
    align-items: center;
}