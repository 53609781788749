.BuildingInfoBlock {
    background-color: #033966;
    padding: 20px;
    height: 100%;

    .information {
        display: flex;
        align-items: center;
        justify-content: right;
        margin-bottom: 10px;

        .icon {
            margin-left: 15px;
            color: #999;

            svg {
                height: 14px;
                vertical-align: middle;
            }

            span {
                font-size: 11px;
                line-height: 14px;
                vertical-align: middle;
                margin-left: 3px;
            }
        }
    }

    .passed {
        margin-bottom: 10px;

        span {
            display: inline-block;
            font-size: 14px;
            line-height: 14px;
            padding: 5px;
            background: #075ea5;
            color: #fff;
            z-index: 3;
        }

        &.is {
            span {
                background: #ffcc01;
                color: #000;
            }
        }
    }

    .tags {
        width: 100%;
        margin-bottom: 20px;

        > div {
            margin-right: 5px;
            background: #ffcc01;
            font-size: 14px;
            padding: 2px 5px;
            display: inline-block;
        }
    }

    .title {
        color: #fff;
    }

    .address {
        margin-bottom: 20px;
        font-size: 16px;
        color: #fff;

        span {
            display: block;

            &.addressLink {
                color: #ffcc01;
                transition: all 0.3s ease-in;
                cursor: pointer;

                &:hover {
                    color: #dfdfdf;
                }
            }
        }
    }

    .info {
        display: flex;
        flex-wrap: wrap;

        .row {
            width: 50%;
            margin-bottom: 15px;

            span:first-child {
                color: #fff;
                font-size: 26px;
                display: block;
                margin-bottom: 5px;
            }

            span:last-child {
                text-transform: uppercase;
                color: #777;
                font-size: 14px;
                display: block;
            }

            span {
                &.costDown {
                    display: inline-block;
                    margin-left: 5px;
                    color: #7bad17;
                    font-size: 16px;
                }

                &.costUp {
                    display: inline-block;
                    margin-left: 5px;
                    color: #a90707;
                    font-size: 16px;
                }
            }
        }
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        > div {
            width: 49%;
            margin-top: 10px;
        }

        .buttonRent {
            width: 100%;

            > div {
                width: 49%;
            }
        }
    }

    .buttonsAdmin {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        > a,
        > div {
            margin-top: 10px;
            width: 48px;
            position: relative;
        }

        .copyText {
            z-index: 2;
            position: absolute;
            left: 50%;
            bottom: -25px;
            transform: translate(-50%, 0);
            transition: all 0.3s ease-in;
            opacity: 0;
            background: #ffcc01;
            font-size: 12px;
            white-space: nowrap;
            padding: 2px 5px;
            border-radius: 5px;

            &.show {
                opacity: 1;
            }
        }
    }
}

@media (max-width: 480px) {
    .BuildingInfoBlock {
        .info {
            .row {
                width: 100%;
            }
        }
    }
}