.BusinessProcessList {
    margin-top: 20px;
    overflow: auto;
    width: 100%;
    height: calc(100% - 103px);
    display: flex;
    flex-direction: column;

    .list {
        display: flex;
    }

    .board {
        width: 250px;
        flex-shrink: 0;
        padding: 10px 5px;
        min-height: 300px;
        display: flex;
        flex-direction: column;

        + .board {
            margin-left: 20px;
        }

        &.default {
            background: #9cd2ff;
        }

        &.process {
            background: #f8f8d8;
        }

        &.discussion {
            background: #f4e5ff;
        }

        &.complete {
            background: #e5f7ff;
        }

        &.rejected {
            background: #e9f5d5;
        }

        &.waitingResponse {
            background: #ffecde;
        }

        &.final {
            background: #f0e0d6;
        }

        .boardDnd {
            width: 100%;
            height: calc(100vh - 195px);
            flex-grow: 1;
            overflow-y: scroll;

            .BusinessProcessItem {
                padding: 5px;
                background: #fff;
                border-bottom: 2px solid #075ea5;
                margin-bottom: 10px;

                .name {
                    font-weight: 700;
                    margin: 5px 0;
                }

                .meta {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;

                    .id {
                        width: 50%;
                        flex-shrink: 0;
                        color: #075ea5;
                        font-size: 12px;
                    }

                    .dateCreated {
                        width: 50%;
                        flex-shrink: 0;
                        text-align: right;
                        color: #999;
                        font-size: 10px;
                    }
                }

                .info {
                    font-size: 12px;
                    color: #999;

                    svg {
                        margin-right: 5px;
                        vertical-align: middle;
                        width: 14px;
                        height: 14px;
                    }

                    span {
                        vertical-align: middle;
                    }
                }
            }
        }

        .boardList {

        }
    }
}