.search_wrapper {
    composes: box from '../form/Box/Box.module.scss';
    transition: all 0.3s ease-in;
    flex-shrink: 0;
}

.input_search {
    composes: input from '../form/Box/Box.module.scss';
    transition: background-color .3s;
}

.ico_search {
    width: 18px;
    height: 17px;
    line-height: 10px;
    transition: all 0.3s ease-in;
    color: #075ea5;

    svg {
        width: 100%;
        height: 100%;
    }
}

.count_find {
    margin-right: 6px;
    line-height: 25px;
    font-size: 12px;
}

.cross {
    composes: cross from '../form/Box/Box.module.scss';
    display: block;
    border-color: transparent;
}