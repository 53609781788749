.item {
    border: 1px solid #075ea5;
    padding: 5px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.4);
    margin-bottom: 10px;

    .row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    .date {
        font-size: 11px;
        color: #999;
        flex-grow: 1;
    }

    .btn {
        width: 15px;
        flex-shrink: 0;
        margin-left: 10px;
        cursor: pointer;
        transition: all 0.3s ease-in;

        &:hover {
            color: #ffcc01;
        }

        svg {
            width: 15px;
            height: 15px;
        }
    }

    .title {
        font-weight: 700;
        font-size: 13px;
        color: #000;

        span {
            color: #075ea5;
            cursor: pointer;
        }
    }

    .description {
        margin-top: 5px;
        font-size: 13px;
        color: #000;
        white-space: pre-wrap;

        .showMore {
            color: #075ea5;
            cursor: pointer;
            margin-left: 5px;

            &:hover {
                color: #ffcc01;
            }
        }
    }
}