.avatarWrapper {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    position: relative;

    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: rgb(0, 0, 0);
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 15%, rgba(3, 57, 102, 1) 100%);
    }
}

.Avatar {
    flex-shrink: 0;
    background-color: #033966;
    position: relative;
    overflow: hidden;

    &.round {
        border-radius: 50%;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.noImage {
        background-image: url('./img/no-image.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1;
        }
    }
}