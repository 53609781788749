.Title {
    h1 {
        display: flex;

        &.headTop {
            flex-wrap: wrap;

            > span {
                width: 100%;
                flex-grow: 1;
                margin-bottom: 20px;
            }

            .interface {
                width: 100%;
                flex-grow: 1;
            }
        }

        > span {
            display: block;
            margin-right: auto;
            color: #fff;
        }

        .interface {
            display: flex;
            justify-content: center;

            .search {
                width: 100%;
                flex-grow: 1;
            }
        }
    }

    h2,
    h3 {
        display: flex;
        text-transform: uppercase;
        color: #777;
        font-size: 20px;
        padding-bottom: 10px;
        margin-bottom: 20px;
        position: relative;

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 150px;
            height: 1px;
            background: #ffcc01;
        }

        > span {
            display: block;
            margin-right: auto;
        }

        .interface {
            display: flex;
            justify-content: center;
        }
    }

    h3 {
        font-size: 18px;
        text-transform: unset;
    }
}

@media (max-width: 768px) {
    .Title {
        h1 {
            flex-wrap: wrap;

            > span {
                width: 100%;
                margin-bottom: 20px;
            }

            .interface {
                width: 100%;
                flex-wrap: wrap;
                justify-content: flex-end;
            }
        }
    }
}

@media (max-width: 480px) {
    .Title {
        h2 {
            font-size: 18px;
        }
    }
}