.MainMenu {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .navigation {
        display: flex;

        &.show {

        }

        a {
            color: #fff;
            font-size: 22px;
            line-height: 40px;
            display: inline-block;
            position: relative;

            + a {
                margin-left: 20px;
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 1%;
                width: 98%;
                height: 0;
                transition: all 0.3s ease-in;
                background-color: #ffcc01;
            }

            &:hover,
            &.active {
                &::before {
                    height: 2px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .MainMenu {
        .navigation {
            display: none;
            position: absolute;
            background: #033966;
            left: 10px;
            width: calc(100% - 20px);
            top: 55px;
            z-index: 6;
            flex-direction: column;
            padding: 40px 60px;
            text-align: center;

            &.show {
                display: flex;
            }

            a {
                + a {
                    margin-left: 0;
                    margin-top: 20px;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .MainMenu {
        .navigation {
            padding: 20px;
        }
    }
}