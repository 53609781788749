.PopupSupportInfo {
    width: 700px;
    height: 100%;

    .content {
        width: 100%;
        height: 100%;
    }

    .blockContent {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow-x: hidden;
    }

    .field {
        width: 100%;
        position: relative;
        display: flex;

        + .field {
            margin-top: 30px;
        }

        > div:first-child {
            width: 110px;
            flex-shrink: 0;
        }

        > div:last-child {
            flex-grow: 1;
        }
    }

    .blockMessages {
        width: 100%;
        margin-top: 30px;

        .field {
            width: 100%;
            margin-bottom: 10px;
            position: relative;
        }
    }

    .messageList {
        width: 100%;

        .item {
            margin-bottom: 10px;
            border-bottom: 1px solid #075ea5;
            padding-bottom: 10px;

            .head {
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;

                .name {
                    font-weight: 700;
                    color: #075ea5;
                }

                .date {
                    color: #999;
                    font-size: 12px;
                }
            }

            .description {
                font-size: 14px;
                line-height: 16px;
                white-space: pre-wrap;
            }
        }
    }
}

@media (max-width: 768px) {
    .PopupSupportInfo {
        width: 500px;
    }
}

@media (max-width: 480px) {
    .PopupSupportInfo {
        width: 350px;

        .field {
            + .field {
                margin-top: 15px;
            }
        }
    }
}