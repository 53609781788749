.PopupBuildingFilter {
    width: 700px;
    height: 100%;

    .content {
        width: 100%;
        height: 100%;
    }

    .blockContent {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow-x: hidden;
    }

    .field {
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;

        h3 {
            width: 100%;
        }

        + .field {
            margin-top: 30px;
        }

        .checkItems {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            > div {
                width: 32.5%;
                margin-right: 0.5%
            }
        }

        .rangeItem {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .col {
                width: 49%;
            }
        }

        .fieldItem {
            width: 50%;
        }
    }
}

@media (max-width: 768px) {
    .PopupBuildingFilter {
        width: 500px;

        .field {
            .checkItems {
                > div {
                    width: 49%;
                    margin-right: 1%
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .PopupBuildingFilter {
        width: 350px;

        .field {
            + .field {
                margin-top: 15px;
            }

            .checkItems {
                > div {
                    width: 100%;
                    margin-right: 0;
                }
            }

            .rangeItem {
                flex-wrap: wrap;

                .col {
                    width: 100%;
                }
            }

            .fieldItem {
                width: 100%;
            }
        }
    }
}