.inner {
    .content {
        .block {
            .gallery {
                height: 100%;
            }

            .info {
                margin-top: 40px;
                justify-content: space-between;
            }

            .relations {
                .list {
                    width: 100%;
                    position: relative;
                    padding-bottom: 30px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    height: auto;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .inner {
        .content {
            .block {
                .headInfo {
                    > div {
                        width: 50% !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .inner {
        .content {
            .block {
                .info,
                .headInfo {
                    flex-wrap: wrap;

                    > div {
                        width: 100% !important;
                    }
                }

                .relations {
                    margin-top: 20px;
                }
            }
        }
    }
}