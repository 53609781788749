.Logo {
    flex-shrink: 0;

    &.short {
        width: 80px;
    }

    &.full {

    }

    a {
        width: 80px;
        height: 80px;
        display: block;
        font-size: 0;
        line-height: 0;

        .image {
            display: block;
            width: 100%;
            height: 100%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('./img/logo-mini.png');
        }
    }
}

@media (max-width: 768px) {
    .Logo {
        &.short {
            width: 45px;

            a {
                width: 45px;
                height: 45px;
            }
        }
    }
}