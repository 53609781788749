.SectionBanner {
    min-height: 550px;
    width: 100%;
    background-image: url('./img/bg-sochi-001.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 120px 0 40px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.25);
    }

    .inner {
        min-height: 430px;
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: center;

        .cols {
            > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        .logo {
            position: relative;
            width: 330px;
            height: 180px;

            .image {
                display: block;
                width: 100%;
                height: 100%;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url('./img/logo-full.png');
            }
        }

        .title {
            font-size: 40px;
            line-height: 50px;
            color: #fff;
            margin-bottom: 15px;
        }

        .buttons {
            margin-top: 50px;
            display: flex;
        }
    }
}

@media (max-width: 768px) {
    .SectionBanner {
        min-height: 350px;
        padding: 80px 0 40px;
        text-align: center;

        .inner {
            min-height: 230px;

            .cols {
                flex-wrap: wrap;
            }

            .title {
                margin-bottom: 10px;
            }

            .buttons {
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }
}