.SidebarLeft {
    position: fixed;
    left: -254px;
    top: 100px;
    bottom: 100px;
    background-color: #fff;
    z-index: 5;
    width: 300px;
    transition: all 0.3s ease-in;

    &.show {
        left: 45px;
        box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.4);
    }

    .content {
        padding: 10px;
        height: 100%;
        overflow: hidden;

        h2 {
            color: #000;
            margin-bottom: 20px;
        }
    }

    .toggle {
        background-color: #fff;
        border-radius: 0 3px 3px 0;
        position: absolute;
        right: -18px;
        top: 50%;
        transform: translate(0, -50%);
        overflow: hidden;
        width: 18px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.4);

        svg {
            transition: all 0.3s ease-in;
            transform: rotate(180deg);
        }

        &.show {
            svg {
                transform: rotate(0deg);
            }
        }
    }

    .list {
        height: calc(100% - 46px);
    }

    .item {
        overflow: hidden;

        + .item {
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid #075ea5;
        }

        h3 {
            text-transform: uppercase;
            color: #777;
            font-size: 15px;
            display: block;
            padding-bottom: 5px;
            margin-bottom: 5px;
            position: relative;

            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 150px;
                height: 1px;
                background: #ffcc01;
            }
        }

        .rangeItem {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .col {
                width: 49%;
            }
        }
    }
}

@media (max-width: 480px) {
    .SidebarLeft {
        left: -300px;

        &.show {
            left: 0;
        }
    }
}