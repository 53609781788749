.SectionAbout {
    background-color: #fff;
    padding: 40px 0;

    .inner {
        .title {
            color: #000;
            font-size: 32px;
            line-height: 40px;
            margin-right: 50px;
        }

        p {
            color: #000;
            font-size: 16px;
            line-height: 20px;
        }
    }
}

@media (max-width: 768px) {
    .SectionAbout {
        .inner {
            .cols {
                flex-wrap: wrap;

                > div {
                    width: 100% !important;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .SectionAbout {
        background-color: #fff;
        padding: 40px 0;

        .inner {
            .title {
                font-size: 30px;
                line-height: 34px;
                margin-right: 20px;
            }
        }
    }
}