.title {
    color: #fff;
}

.list {
    width: 100%;
    position: relative;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
        width: 31%;
        height: 250px;
        background-color: #033966;
        position: relative;
        box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.4);
        transition: all 0.3s ease-in;
        cursor: pointer;
        margin: 0 0 15px 15px;

        &.disabled {
            filter: grayscale(1);
        }

        &:hover {
            box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.6);
        }

        .itemContent {
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            padding: 10px 10px 10px 15%;
            text-align: right;
            z-index: 3;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .head {
                font-size: 22px;
                line-height: 26px;
                color: #fff;
            }

            .description {
                color: #fff;
                line-height: 20px;
            }
        }
    }
}