.BookingList {
    height: calc(100% - 118px);

    .name {
        flex-grow: 1;
    }

    .status {
        width: 200px;
        flex-shrink: 0;
    }

    .dateStart,
    .dateFinish {
        width: 150px;
        flex-shrink: 0;
    }
}

@media (max-width: 768px) {
    .BookingList {
        display: block;
        padding: 0;
        margin-bottom: 0;

        .name,
        .status,
        .dateStart,
        .dateFinish {
            position: relative;
            display: block;
            width: 100%;
            padding-right: 8px;
            text-overflow: ellipsis;
            white-space: nowrap;

            &::before {
                display: inline-block;
                width: 100px;
                padding: 8px 10px;
                background-color: #075ea5;
                color: #fff;
                margin-right: 10px;
                border-bottom: 1px solid #fff;
            }
        }

        .name::before {
            content: 'Недвижимость';
        }

        .status::before {
            content: 'Статус';
        }

        .dateStart::before {
            content: 'Дата начала';
        }

        .dateFinish::before {
            content: 'Дата начала';
            border-bottom: none;
        }
    }
}

@media (max-width: 480px) {
    .BookingList {
        font-size: 13px;

        .name,
        .status,
        .dateStart,
        .dateFinish {
            &::before {
                width: 80px;
            }
        }
    }
}