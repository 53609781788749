.PanelView {
    padding: 5px 0;
    margin: 0;
    position: relative;
    background-color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-image: url('./img/bg-main.jpg');
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #033966;
        background: linear-gradient(135deg, #000000 0%, transparent 60%, transparent 80%, #ffcc01 100%);
        z-index: 1;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background-color: #033966;
        background-image: url('./img/bg-pattern.png');
        background-position: center;
        background-size: 3px;
        background-repeat: repeat;
        z-index: 2;
    }

    .main {
        z-index: 3;
        height: 100%;
    }
}