.DefaultView {
    padding: 100px 0 0;
    margin: 0;
    position: relative;
    background-color: #fff;

    &.isMain {
        padding-top: 0;

        .main {
            padding: 0;
        }
    }

    .main {
        padding: 40px 0;
        min-height: calc(100vh - 303px);
    }
}

@media (max-width: 768px) {
    .DefaultView {
        padding: 50px 0 0;
    }
}

@media (max-width: 480px) {
    .DefaultView {
        .main {
            padding: 20px 0;
        }
    }
}