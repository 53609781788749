.Gallery {
    width: 100%;
    position: relative;

    .carousel {
        width: 100%;
        height: 100%;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
        }
    }
}