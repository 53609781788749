.withOutStyle {
    list-style: none;
    padding-left: 15px;
    margin-bottom: 20px;

    li {
        line-height: 20px;

        + li {
            margin-top: 10px;
        }
    }
}