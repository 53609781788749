.PopupMap {
    width: 100vw;
    height: 100%;

    .content {
        width: 100%;
        height: 100%;
    }

    .map {
        width: 100%;
        height: 100%;
    }
}