.BuildingDocumentsBlock {
    background: #033966;
    padding: 30px;
    box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.4);
    height: auto;
    margin-bottom: 20px;

    a {
        color: #fff;
    }
}