.inner {
    .content {
        .block {
            .gallery {
                margin-bottom: 40px;
            }

            .description {
                white-space: pre-wrap;
                line-height: 20px;
                font-size: 16px;
                margin-bottom: 20px;
                text-align: justify;

                ol,
                ul {
                    padding-left: 20px;
                }
            }

            .information {
                display: flex;
                align-items: center;

                .icon {
                    margin-right: 15px;
                    color: #999;

                    svg {
                        height: 14px;
                        vertical-align: middle;
                    }

                    span {
                        font-size: 11px;
                        line-height: 14px;
                        vertical-align: middle;
                        margin-left: 3px;
                    }
                }
            }
        }
    }

    .relations {
        margin-top: 40px;

        .list {
            width: 100%;
            position: relative;
            padding-bottom: 30px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            height: auto;
        }
    }
}