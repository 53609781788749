.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex: 1 1 auto;
    min-height: 0;
}

.content {
    height: calc(100% - 35px);
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    min-height: 50px;
}

.content > div {
    flex: 1 1 auto;
}

.tabs {
    display: flex;
    flex: none;
    flex-wrap: wrap;
}

.top .tabs {
    border-bottom: 1px solid #075ea5;
}

.bottom .tabs {
    align-items: flex-start;
    border-top: 1px solid #075ea5;
}

.top.error_tabs .tabs {
    border-bottom: 1px solid #fe5a9d;
}

.bottom.error_tabs .tabs {
    border-top: 1px solid #fe5a9d;
}

.icon {
    width: 27px;
    background-position: center;
    background-repeat: no-repeat;
}

.title-empty {
    flex: none;
    width: 23px;
}

.tab {
    display: flex;
    height: 23px;
    border: 1px solid #075ea5;
    padding: 0 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 23px;
    color: #075ea5;
    cursor: pointer;
    background-color: #fff;
    white-space: nowrap;
    transition: background-color 0.1s;
    user-select: none;
}

.icon-count {
    width: auto;
    background-position: 10px center;
}

.tab span {
    font-size: inherit;
    line-height: inherit;
}

.tab:not(.active):hover {
    background-color: #e7f3f5;
}

.active {
    composes: tab;
    background-color: #075ea5;
    color: #fff;
    position: relative;
}

.top .active {
    border-bottom: 1px solid transparent;
}

.bottom .active {
    border-top: 1px solid transparent;
}

.tab.error {
    background-color: #fff9fb;
    border-color: #fe5a9d;
}

.top .tab.error.active {
    border-bottom: 1px solid transparent;
}

.bottom .tab.error.active {
    border-top: 1px solid transparent;
}

.count {
    flex: none;
    color: #ff9375;
    font-size: 12px;
    font-weight: bold;
    margin-left: 7px;
}