.title {
    color: #fff;
}

.columns {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    height: calc(100vh - 72px);

    .col {
        width: 100%;
        min-height: 350px;
        max-height: 350px;
        margin-bottom: 30px;
        background-color: #fff;
        border: 1px solid #075ea5;
        box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.4);
        padding: 15px;

        &.col-2 {
            width: 49%;
        }

        &.col-3 {
            width: 32%;
        }
    }

    .list {
        height: calc(100% - 56px);

        .block {
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            + .block {
                padding-top: 10px;
                margin-top: 10px;
                border-top: 1px solid #075ea5;
            }

            &.noClick {
                cursor: unset;
            }

            .meta {
                flex-grow: 1;
                width: 100%;
                padding-left: 10px;
            }

            .title {
                font-weight: 700;
                margin-bottom: 10px;
            }

            .name {

            }

            svg {
                width: 15px;
                margin-right: 5px;
            }

            .date {
                font-size: 12px;
                line-height: 12px;
                color: #999;
            }
        }

        .row {
            display: flex;

            + .row {
                margin-top: 10px;
            }

            .label {
                width: 30%;
                font-weight: 700;
            }

            .param {
                width: 70%;
                text-align: right;
            }
        }
    }

    .link {
        color: #075ea5;
        cursor: pointer;
    }
}

@media (max-width: 1300px) {
    .columns {
        .col {
            &.col-3 {
                width: 49%;
            }
        }
    }
}

@media (max-width: 1024px) {
    .columns {
        .col {
            &.col-2 {
                width: 100%;
            }
        }
    }
}

@media (max-width: 900px) {
    .columns {
        .col {
            &.col-3 {
                width: 100%;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media (max-width: 400px) {
    .columns {
        .col {
            padding: 10px;
        }
    }
}