.container {
    overflow-y: auto;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #b8b8b8;
    padding: 10px 15px;
    background-color: #fff;
}