.InfoItem {
    display: flex;
    overflow: auto;
    width: 100%;
    padding: 5px 15px;
    overflow-wrap: anywhere;

    .name {
        flex-grow: 1;
    }

    .date {
        width: 150px;
        flex-shrink: 0;
    }
}