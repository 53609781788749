.FileUploader {
    width: 100%;

    .field {
        display: flex;

        input {
            display: none;
        }

        .info {
            display: flex;

            .type {
                font-size: 20px;
                line-height: 40px;
                text-transform: uppercase;
                background: #075ea5;
                color: #fff;
                padding: 0 10px;
                border-radius: 8px;
            }
        }

        .progress {
            display: block;
            width: 100%;
            height: 18px;
            margin: 11px 0;
            background: #dfdfdf;
            font-size: 11px;

            span {
                padding: 2px 5px;
                display: block;
                background: #ffcc01;
                white-space: nowrap;
            }
        }
    }
}