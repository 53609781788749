.BlockItem {
    width: 31%;
    height: 250px;
    background-color: #033966;
    position: relative;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease-in;
    cursor: pointer;
    margin: 0 0 15px 15px;

    &:hover {
        box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.6);
    }

    .itemContent {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 10px 10px 10px 15%;
        text-align: right;
        z-index: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .head {
            font-size: 22px;
            line-height: 26px;
            color: #fff;
            margin-bottom: 10px;
        }

        .address {
            color: #fff;
        }

        .description {
            color: #fff;

            p {
                line-height: 20px;
            }
        }
    }

    .itemInfo {
        position: relative;

        .passed {
            background: #3E88C6;
            color: #fff;
            display: inline-block;
            font-size: 14px;
            line-height: 14px;
            padding: 5px;
            z-index: 3;

            &.is {
                background: #ffcc01;
                color: #000;
            }
        }

        .counter {
            color: #fff;
            font-size: 20px;
            margin: 5px 0;
        }

        .area {
            h3 {
                font-size: 16px;
                margin-bottom: 0;
                color: #aaa;
                text-transform: uppercase;
            }

            > div {
                color: #ffcc01;
                font-size: 18px;
                line-height: 16px;
            }
        }
    }

    .type {
        position: absolute;
        left: -15px;
        bottom: 35px;
        width: auto;
        height: 20px;
        background-color: #075ea5;
        color: #fff;
        padding: 0 5px;
        z-index: 3;
        font-size: 14px;
        line-height: 20px;
    }

    .information {
        position: absolute;
        left: -15px;
        bottom: 15px;
        width: auto;
        height: 20px;
        background-color: #ffcc01;
        color: #000;
        padding: 0 5px;
        z-index: 3;

        .icon {
            font-size: 12px;
            display: inline-block;

            + .icon {
                margin-left: 8px;
            }

            svg {
                display: inline-block;
                vertical-align: top;
            }

            span {
                display: inline-block;
                font-size: 10px;
                vertical-align: top;
                margin-left: 4px;
            }
        }
    }
}

@media (max-width: 1024px) {
    .BlockItem {
        width: 48%;
    }
}

@media (max-width: 768px) {
    .BlockItem {
        width: 100%;
    }
}