.Footer {
    .footerFirst {
        background-color: #033966;
        padding: 40px 0;

        .inner {
            display: flex;
            justify-content: space-between;
        }
    }

    .footerSecond {
        background-color: #000;
        padding: 5px 0;

        .copyright {
            text-align: center;
            color: #fff;
            font-size: 14px;
            line-height: 20px;
        }
    }
}

@media (max-width: 768px) {
    .Footer {
        .footerFirst {
            .inner {
                flex-wrap: wrap;
            }
        }
    }
}