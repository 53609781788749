.Avatar {
    width: 200px;
    flex-shrink: 0;
    background-color: #ccc;
    position: relative;
    overflow: hidden;

    &.round {
        border-radius: 50%;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.noImage {
        background-image: url('./img/no-image.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #075ea5;
            background: linear-gradient(135deg, #000000 0%, transparent 60%, transparent 80%, #ffcc01 100%);
            z-index: 1;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
            background-color: #075ea5;
            background-image: url('./img/bg-pattern.png');
            background-position: center;
            background-size: 3px;
            background-repeat: repeat;
            z-index: 2;
        }
    }
}