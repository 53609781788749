.Grid {
    display: flex;

    &.wrap {
        flex-wrap: wrap;
    }

    &.vTop {
        align-items: center;
    }

    &.hTop {
        justify-content: center;
    }
}