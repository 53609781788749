.ToolsPanel {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 15px 10px;
    z-index: 7;
    display: flex;
    width: 46px;
    flex-direction: column;
    justify-content: center;

    .icon {
        color: #fff;
        transition: all 0.3s ease-in;
        cursor: pointer;
        font-size: 18px;
        text-align: center;
        position: relative;
        width: 26px;

        &:hover {
            opacity: 0.7;
        }

        a {
            color: #fff;
        }

        + .icon {
            margin-top: 20px;
        }

        .counter {
            position: absolute;
            right: -12px;
            bottom: -5px;
            border-radius: 50%;
            background: #ffcc01;
            text-align: center;
            z-index: 2;
            font-size: 12px;
            line-height: 17px;
            width: 17px;
            height: 17px;
        }
    }
}

@media (max-width: 480px) {
    .ToolsPanel {
        left: auto;
        bottom: auto;
        right: 12px;
        top: 5px;
        padding: 10px 5px;
        z-index: 5;
        display: flex;
        flex-direction: row;
        width: auto;

        .icon {
            width: auto;

            + .icon {
                margin-top: 0;
                margin-left: 20px;
            }

            .counter {
                position: absolute;
                left: -12px;
                top: -5px;
            }
        }
    }
}