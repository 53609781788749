.CompilationList {
    height: calc(100% - 118px);

    .name {
        flex-grow: 1;
    }

    .date {
        width: 180px;
        flex-shrink: 0;
    }

    .count {
        width: 100px;
        flex-shrink: 0;
    }
}

@media (max-width: 768px) {
    .CompilationList {
        display: block;
        padding: 0;
        margin-bottom: 0;

        .name,
        .date,
        .count {
            position: relative;
            display: block;
            width: 100%;
            padding-right: 8px;
            text-overflow: ellipsis;
            white-space: nowrap;

            &::before {
                display: inline-block;
                width: 100px;
                padding: 8px 10px;
                background-color: #075ea5;
                color: #fff;
                margin-right: 10px;
                border-bottom: 1px solid #fff;
            }
        }

        .name::before {
            content: 'Название';
        }

        .date::before {
            content: 'Дата создания';
        }

        .count::before {
            content: 'Количество';
            border-bottom: none;
        }
    }
}

@media (max-width: 480px) {
    .CompilationList {
        font-size: 13px;

        .name,
        .date,
        .count {
            &::before {
                width: 80px;
            }
        }
    }
}